@charset "UTF-8";
@import url("https://at.alicdn.com/t/font_1303752_yg45x6aonj.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; }

html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f5f5f9 !important; }

body {
  font-family: 'PingFang-Medium', '微软雅黑', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body p {
  margin-bottom: 0;
  word-break: break-all; }

body input,
body textarea {
  border: 0;
  outline: 0; }
  body input::-webkit-input-placeholder,
  body textarea::-webkit-input-placeholder {
    color: #999; }
  body input::-moz-input-placeholder,
  body textarea::-moz-input-placeholder {
    color: #999; }
  body input::-ms-input-placeholder,
  body textarea::-ms-input-placeholder {
    color: #999; }

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.hdz-block-small-space {
  width: 100%;
  height: 1.33vw;
  background: transparent; }

.hdz-block-medium-space {
  width: 100%;
  height: 2.67vw;
  background: transparent; }

.hdz-block-large-space {
  width: 100%;
  height: 5.33vw;
  background: transparent; }

.detail-kv {
  width: 100%;
  min-height: 11.73vw;
  overflow: hidden;
  padding: 2.67vw;
  margin: 1px 0;
  background: #FFF;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .detail-kv span {
    line-height: 1.5;
    font-size: 3.73vw; }
    .detail-kv span:nth-child(1) {
      min-width: 16vw;
      color: #999;
      margin-right: 2.4vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; }
    .detail-kv span:nth-child(2) {
      font-weight: bold;
      color: #555;
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start; }

.project-intro {
  width: 100%;
  height: 20.27vw;
  overflow: hidden;
  background: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .project-intro > div {
    width: 33.33%;
    text-align: justify; }
    .project-intro > div p {
      width: 100%;
      max-height: 5.33vw;
      line-height: 5.33vw;
      padding: 0 1.33vw;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      .project-intro > div p:nth-child(1) {
        font-size: 4.27vw;
        font-weight: bold;
        color: #333;
        margin-bottom: 2.67vw; }
      .project-intro > div p:nth-child(2) {
        font-size: 3.73vw;
        color: #555; }
    .project-intro > div:nth-child(2) {
      border-left: 1px solid #E6E6E6;
      border-right: 1px solid #E6E6E6; }

.am-modal-button-group-v {
  max-height: 80vh;
  overflow: auto; }
