$WHITE: #FFF;
$BLACK: #000;
$GRAY_333: #333;
$GRAY_555: #555;
$GRAY_999: #999;
$GRAY_CCC: #CCC;
$GRAY_EEE: #EEE;

$PRICE_COLOR: #FE6C15;

$TAG_1_COLOR: #FC5457;
$TAG_1_BORDER_COLOR: #FEBDBE;
$TAG_2_COLOR: #1082F5;
$TAG_2_BORDER_COLOR: #A8D1FB;

$TAB_BOTTOM_COLOR: #0572E4;

$SIZE_30: 8vw;
$SIZE_20: 5.33vw;
$SIZE_10: 2.67vw;
$SIZE_5: 1.33vw;

$FONT_SIZE_37: 9.87vw; // 37px
$FONT_SIZE_30: 8vw; // 30px
$FONT_SIZE_28: 7.47vw; // 28px
$FONT_SIZE_26: 6.93vw; // 26px
$FONT_SIZE_24: 6.4vw; // 24px
$FONT_SIZE_22: 5.87vw; // 22px
$FONT_SIZE_20: $SIZE_20; // 20px
$FONT_SIZE_18: 4.8vw; // 18px
$FONT_SIZE_16: 4.27vw; // 16px
$FONT_SIZE_14: 3.73vw; // 14px
$FONT_SIZE_12: 3.2vw; // 12px

$CONTENT_HEIGHT: calc(100vh - 11.73vw - 50px);

@mixin text-overflow($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@mixin display-flex($direction: row, $justify: center, $align: center, $display: flex) {
    display: $display;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin position($type: relative, $top: 0, $left: 0, $z-index: 999, $bottom: 0, $right: 0) {
    position: $type;
    top: $top;
    left: $left;
    bottom: $bottom;
    right: $right;
    z-index: $z-index;
}

@mixin font($font-size: 3.2vw, $color: #000, $font-weight: normal) {
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
}
